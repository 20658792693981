import {
  CreateCustomerPayload,
  CreateCustomerResponse,
  Customer,
  CustomersResponse,
  CustomersSearchParams,
  InviteCustomerResponse,
  ValidateCustomerEmailPayload,
} from '~/types/customers'
import { ApiKy } from './index'

const { stripEmptyKeys } = useHelpers()

export default class Api extends ApiKy {
  get(searchParams: CustomersSearchParams) {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(this.url, { searchParams: params })
      ?.json<CustomersResponse>()
  }

  getSingle(customerId: string) {
    return this.ky.get(`${this.url}/${customerId}`)?.json<Customer>()
  }

  createCustomer(payload: CreateCustomerPayload) {
    return this.ky
      .post(this.url, { json: payload })
      ?.json<CreateCustomerResponse>()
  }

  updateSingle(customerId: string, payload: Partial<Customer>) {
    return this.ky
      .patch(`${this.url}/${customerId}`, { json: payload })
      ?.json<Customer>()
  }

  inviteCustomer(
    customerId: string,
    userData: {
      email: string
      firstName: string
      lastName: string
      role: string
    },
  ) {
    return this.ky
      .post(`${this.url}/${customerId}/users/invite`, {
        json: { invites: [userData] },
      })
      ?.json<InviteCustomerResponse>()
  }

  validateEmail(payload: ValidateCustomerEmailPayload) {
    return this.ky.post(`${this.url}/validate-email`, { json: payload })?.json()
  }
}
