/* eslint-disable no-unused-vars */
import { CountryCode, CurrencyCode, ServerLocation } from './countries'
import { SearchParams } from './pagination'
import { Partner, PartnerRecord } from '~/types/partners'
import { TranslationArgs } from '~/types/translation'
import { ProductLine } from './prices'
import { SalesforceCall } from '~/types/admin'

type Customer = {
  id: string
  name: string
  country: keyof typeof CountryCode
  city: string
  street: string
  postalCode: string
  state: string
  contactEmail: string
}

export enum AddonType {
  CLOUD_DATABASE = 'cloudDatabase',
  PRINT_HISTORY_RETENTION = 'printHistoryRetention',
  SUPPORT_LEVEL = 'supportLevel',
  CLOUD_PRINTING_INTEGRATION = 'cloudPrintingIntegration',
  NON_PRODUCTION_ENVIRONMENT = 'nonProductionEnvironment',
  SAP_INTEGRATION = 'sapIntegration',
  NICELABEL_ACADEMY_UNLIMITED = 'nicelabelAcademyUnlimited',
  ORACLE_INTEGRATION = 'oracleIntegration',
  CLOUD_AUTOMATION_SERVICE = 'cloudAutomationService',
  HYBRID_DEPLOYMENT = 'hybridDeployment',
  OFFLINE_LICENSE_KEY = 'offlineLicenseKey',
}

export enum ProductType {
  CLOUD = 'cloud',
}

export enum Plan {
  DESIGNER = 'DESIGNER',
  ESSENTIALS = 'ESSENTIALS',
  BUSINESS = 'BUSINESS',
  DESIGNER_PRO = 'DESIGNER_PRO',
  LMS_ENTERPRISE = 'LMS_ENTERPRISE',
  LMS_PRO = 'LMS_PRO',
  POWERFORMS_SUITE = 'POWERFORMS_SUITE',
}

export enum Env {
  PRODUCTION = 'Prod',
  SANDBOX = 'Sandbox',
}

export enum Status {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  TRIAL = 'TRIAL',
  CANCELLED = 'CANCELLED',
  ON_HOLD = 'ON_HOLD',
}

export enum BillingPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  YEARS_3 = 'YEARS_3',
}

export enum PaymentMethod {
  Card = 'CARD',
  PayPal = 'PAYPAL',
  Offline = 'OFFLINE',
}

export enum PaymentSourceType {
  EXISTING_CARD = 'EXISTING_CARD',
  NEW_CARD = 'NEW_CARD',
  EXISTING_PAYPAL = 'EXISTING_PAYPAL',
  NEW_PAYPAL = 'NEW_PAYPAL',
  WIRE_TRANSFER = 'WIRE_TRANSFER',
}

// Subscription
export type Subscription = {
  notes: string
  numberOfPrinters: number
  billingPeriod: BillingPeriod
  billingAmount: number
  currencyCode: CurrencyCode
  customer: Customer | null
  partner: Partner | null
  id: string
  status: Status
  addons: { type: string; [key: string]: string }[]
  subscriptionName: string
  plan: Plan
  chargebeeSubscriptionId: string
  serverLocation: ServerLocation
  termEnd: string
  termStart: string
  trialStart: string
  trialEnd: string
  nicelabelAccountId: string
  accountOwnerEmail: string
  productOrderNumber: string
  endTerm: string
  trialExtended: boolean
  issuesCreditNoteOnCancellation: boolean
  renewable: boolean
  instances: NiceLabelInstance[]
  productLine: ProductLine
  salesforceCallFailed?: boolean
  salesforceCall?: SalesforceCall
  reseller?: Partial<Partner>
  createdAt?: string
  createdBy?: PartnerRecord
  updatedBy?: PartnerRecord
  deleteScheduledOn?: string
  opportunityId?: string
}

export type SubscriptionPriceType = {
  serverLocation: ServerLocation
  currencySign: string
  currencyCode: CurrencyCode
  recommendedPrice: number
  partnerPrice: number
  taxPercentage: number
  taxAmount: number
  discountPercentage: number
  discountAmount: number
  promotionDiscountAmount?: number
  promotionDiscountPercentage?: number
  period: BillingPeriod
}
export type SubscriptionsResponse = {
  data: Subscription[]
  pages: number
  total: number
}

export type NiceLabelInstance = {
  id: string
  subscriptionPlan: string
  name: string
  type: string
  nicelabelCustomerId: string
  accessLink: string
  serverLocation: string
  inviteEmails: string
  isProvisioned: boolean
  licenseKey: string | null
}

export type NiceLabelInstancesResponse = NiceLabelInstance[]

export interface SubscriptionsSearchParams extends SearchParams {
  customerId?: string
  partnerId?: string
  status?: string
  billingPeriod?: string
  subscriptionName?: string
  trialCancelled?: boolean
  includeDeleted?: boolean
}

export interface CreateSubscriptionArgs {
  serverLocation: ServerLocation
  billingPeriod: BillingPeriod
  payment_source_id?: string
  customerId: string
  numberOfPrinters: string
  partnerId?: string
  accountOwnerEmail: string
  productOrderNumber?: string
  notes?: string
  addons: SubscriptionAddons
  subscriptionPlan: string
  environment: EnvironmentInstance
}

export type ColumnNames = Map<
  string,
  {
    translation: TranslationArgs
    sorting?: string
  }
>

export type SubscriptionAddons = {
  [key: string]: { [key: string]: string | null }
}

export type EnvironmentInstance = {
  [key: string]: { name: string; urlPrefix: string; inviteEmails: string }
}

export type EnvironmentProps = {
  uniqueUrlPrefix: string
  instanceName: string
}

export interface Environment {
  prodEnv: EnvironmentProps
  sandboxEnv: EnvironmentProps
}

export interface Access {
  environment: Environment
  accessEmailDomain: string
}

export enum EnvironmentType {
  PRODUCTION = 'prodEnv',
  SANDBOX = 'sandboxEnv',
}

export enum EnvironmentProperties {
  UNIQUE_URL_PREFIX = 'uniqueUrlPrefix',
  INSTANCE_NAME = 'instanceName',
}

export interface UpdateEnvironment {
  input: string
  type: EnvironmentType
  property: EnvironmentProperties
}

export type HighlightSection = {
  body?: string
  title?: string
  icon?: string
  background?: boolean
}

export type MoreInformation = {
  title?: string
  body: string
  link?: string
  highlightSection?: HighlightSection
}

export enum AddonConfigurationType {
  NEW_SUBSCRIPTION = 'NEW_SUBSCRIPTION',
  CHANGE_SUBSCRIPTION = 'CHANGE_SUBSCRIPTION',
}

type AddonConfiguration = {
  disabledMessage?: {
    [key in AddonConfigurationType]?: {
      message: string
      showMessage: boolean
    }
  }
}

export interface Addon {
  productLine?: ProductLine[]
  title: string
  desc: string
  value: string
  moreInformation?: MoreInformation
  addOnValue: string | null
  options: {
    [key: string]: {
      title: string
      compactTitle?: string
      desc: string | null
      value: string | string[]
      chargebeeId?: string
      options?: { [key: string]: Option }
    }
  }
  hiddenInTrialMode?: boolean
  new?: boolean
  configuration?: AddonConfiguration
}

export interface Addons {
  [key: string]: Addon
}

interface Option {
  label: number
  value: string
  options?: { [key: string]: Option }
}

export interface Product {
  id: string
  label: string
  logo: string
  value: string
}

export interface Products {
  [key: string]: Product
}

export type CheckEmailDomainResponse = {
  formatValid: boolean
  domainNotEmailProvider: boolean
}

export type checkUniqueInstanceName = {
  isUnique: boolean
}

export enum BillingTableType {
  INVOICES = 'invoices',
  CREDIT_NOTES = 'credit-notes',
}

export type UpcomingRenewal = {
  id: string
  termEnd: string
}

export interface DownloadCsvSearchParams extends SearchParams {
  customerId?: string
  partnerId?: string
  subscriptionName?: string
  billingPeriod?: string
  status?: string
}
