import { ApiKy } from './index'
import {
  CheckAvailableVouchersResponse,
  DownloadCsvSearchParams,
  Voucher,
  VoucherPayload,
  VoucherSearchParams,
  VouchersResponse,
} from '~/types/vouchers'
import { Reseller } from '~/types/resellers'
import { SubscriptionsEstimateResponse } from '~/types/prices'

const { stripEmptyKeys } = useHelpers()

export default class Api extends ApiKy {
  get(searchParams: VoucherSearchParams) {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(this.url, { searchParams: params })
      ?.json<VouchersResponse>()
  }

  validate = (voucherCode: string) => {
    return this.ky
      .post(`${this.url}/validate-code`, { json: { code: voucherCode } })
      ?.json<{ valid: boolean }>()
  }

  getSingle = (voucherCode: string) => {
    return this.ky
      .get(`${this.url}/find-by-code/${voucherCode}`)
      ?.json<Voucher>()
  }

  getResellers = (voucherCode: string, searchParams: VoucherSearchParams) => {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(`${this.url}/find-by-code/${voucherCode}/resellers`, {
        searchParams: params,
      })
      ?.json<Reseller[]>()
  }

  postVoucherEstimate = (voucherCode: string) => {
    return this.ky
      .get(`${this.url}/find-by-code/${voucherCode}/estimate`)
      ?.json<SubscriptionsEstimateResponse>()
  }

  claim = (payload: VoucherPayload) => {
    return this.ky.post(`${this.url}/claim`, { json: payload })?.json()
  }

  downloadCsv(searchParams: DownloadCsvSearchParams) {
    const params = stripEmptyKeys(searchParams)
    return this.ky.get(`${this.url}/download-csv`, {
      searchParams: params,
    })
  }

  available = (partnerId: string) => {
    return this.ky
      .get(`${this.url}/available`, { searchParams: { partnerId } })
      .json<CheckAvailableVouchersResponse>()
  }

  postSendVoucherOnboarding = (
    voucherId: string,
    resellerId: string,
    resellerEmail: string,
  ) => {
    return this.ky
      .post(`${this.url}/${voucherId}/send-voucher-onboarding`, {
        json: { resellerId, email: resellerEmail },
      })
      ?.json()
  }
}
